* {
    box-sizing: border-box;
}

.MuiFormControl-root {
    width: 100%;
}

.main-wrapper {
    width: 100%;
    height: calc(100vh - 111px);
    margin: auto;
    overflow: auto;
    background-color: #edf0f2;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.main-wrapper main {
    width: calc(100% - 200px);
    padding: 20px 24px;
}

.main-wrapper aside {
    width: 200px;
    padding: 20px 15px;
    background-color: white;
    position: sticky;
    top: 0;
}

.main-wrapper aside figure {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: 0;
}
.main-wrapper aside figure img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

@media screen and (max-width: 767px) {
    .main-wrapper {
        height: calc(100vh - 97px);
    }
}

@media screen and (max-width: 600px) {
    .main-wrapper main {
        width: calc(100% - 50px);
    }
    .main-wrapper aside {
        width: 50px;
        padding: 20px 10px;
    }
    .main-wrapper aside figure {
        height: 40px;
    }
}
@media screen and (max-width: 576px) {
    .main-wrapper {
        height: calc(100vh - 97px);
    }
    .main-wrapper main {
        padding: 15px 10px;
    }
}

.insurance-dropdown .MuiFormLabel-root {
    top: -8px;
}

.insurance-dropdown .MuiFormLabel-root.MuiFormLabel-filled {
    top: 0px;
}

.insurance-dropdown .MuiFormLabel-root.focused {
    top: 0px;
}

.modal-overlay {
    background: rgba(0, 0, 0, 0.6);
}

.insurance-dropdown .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.insurance-dropdown .MuiSelect-select h4 {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
    font-weight: 400;
}