.btn {
    padding: 15px 50px;
    border-radius: 5px;
    color: #53C392;
    box-shadow: none;
    transition: .2s;
    height: 49px;
    white-space: nowrap;
    min-width: 90px;
    outline: none;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #eff1f2;
    background-color: white;
    box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9), 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-primary{
    border: 1px solid #eff1f2;
    color: white;
    background-image: linear-gradient(to right, #152567 , #53C392);
}
.btn.btn-delete{
    background-color: #ed5e68;
    color: white;
    border: none;
}
.btn.btn-simple{
    background-color: #a4a4a4;
    color: white;
    border: none;
}
.btn.btn-simple:hover{
    background-color: #919191;
}
.btn.small{
    padding: 10px 20px;
    height: auto;
    min-width: 70px;
    font-size: 14px;
}
.btn.w-100{
    width: 100%;
}
.btn.on-load{
    color: #ffffff96;
    cursor: progress;
}
.btn.on-load .dimmer{
    display: block !important;
}
.btn:disabled, .btn.disabled{
    opacity: .75;
    cursor: not-allowed;
    pointer-events: none;
}


@media screen and (max-width: 576px){
    .btn{
        height: 40px;
        min-width: 70px;
        font-size: 14px;
        padding: 10px 20px;
    }
    .btn.small{
        padding: 5px 13px;
        height: auto;
        min-width: 50px;
        font-size: 10px;   
    }
}

.btn.small .ui.loader, .btn.small .ui.loader::before, .btn.small .ui.loader::after{
    width: 20px;
    height: 20px;
}
.btn.small .ui.loader:before, .btn.small .ui.loader:after{
    margin-left: -10px;
}