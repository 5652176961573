.image-preview {
    width: 65px;
    height: 50px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-right: 15px;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.image-preview .remove-image{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(60%, -50%);
    color: red;
    cursor: pointer;
    width: 30px;
}

.MuiFormControlLabel-root .MuiTypography-root{
    flex: 1;
}
.MuiFormControlLabel-root{
    margin: 0 !important;
}