* {
  outline: none;
  box-sizing: border-box;
  word-break: break-word;
}


ul,
ol {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    line-height: 1.4;
    font-weight: 500;
}
p {
  font-weight: 400;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --white: #fff;
    --black: #000;
    --light-green: #53c392;
    --berwick-berry: #7183a9;
    --light-gray: #4a4a4a;
    --bright-red: #ff4d2e;
    --bright-light-red: #ee4040;
    --bright-red-mini: #fca28f;
    --bright-purple: #0303fc;
    --bright-purple-mini: #85b4ff;
    --persian-green: #009991;
    --persian-green-mini: #00adad;
    --greenish-brown: #fcaa05;
    --greenish-brown-mini: #ffd991;
    /* box shadow */
    --app-bar-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.03);
}

a {
  text-decoration: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.align-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
}
.align-flex-end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}

.uppercase {
    text-transform: uppercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.underline {
    text-decoration: underline !important;
}
.bold {
    font-weight: 800 !important;
}
.semi-bold {
    font-weight: 600 !important;
}
.regular {
    font-size: 500 !important;
}
.thin {
    font-weight: 400 !important;
}
.center {
    text-align: center !important;
}
.right {
    text-align: right !important;
}
.left {
    text-align: left !important;
}

.pointer {
    cursor: pointer !important;
}
.p-0 {
    padding: 0px !important;
}
.pl-30 {
    padding-left: 30px !important;
}
.pr-30 {
    padding-right: 30px !important;
}
.pt-10 {
    padding-top: 10px !important;
}
.pt-30 {
    padding-top: 30px !important;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-10border {
    margin-bottom: 10px !important;
    /* background-color: #ee4040; */
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
    margin-left: 30px;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px;
}
.mr-30 {
    margin-right: 30px;
}

.color-blue {
    color: #152567;
}
.color-text {
    color: #707070 !important;
}
.color-white {
    color: white !important;
}
.color-green {
    color: green !important;
}
.bg-gray {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 7px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.bg-white {
    padding: 20px;
    background-color: white;
    border-radius: 7px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
@media screen and (max-width: 576px) {
    .bg-white {
        padding: 15px 10px;
    }
}
.react-file-reader-button {
    display: inline;
}

.MuiFormControl-root {
  width: 100%;
}

.page-personal-info .MuiAvatar-square {
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.page-personal-info .MuiAvatar-square img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-input.MuiOutlinedInput.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
.notranslate {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.98) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
.MuiChip-root{
  height: auto !important;
  padding: 5px 0px !important;
}

.MuiChip-root .MuiChip-label{
  white-space: initial;
}
.MuiButton-outlinedPrimary{
    color: var(--light-green) !important;
    border-color: var(--light-green) !important;
}
.MuiButton-containedPrimary{
    background-color: var(--light-green) !important;
}