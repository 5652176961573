.table-cover {
    width: 100%;
    margin: auto;
    padding: 10px;
    overflow: auto;
    position: relative;
    border-radius: 8px;
    box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9), 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
    border: 1px solid #ffffff6b;
}
.table-cover .MuiTable-root{
    min-width: auto;
}
table {
    width: 100%;
    border-collapse: collapse;
}

table thead tr {
    width: 100%;
    background-color: #f8f9fa;
}

table {
    width: 100%;
    border-collapse: collapse;
}
table th,
table td {
    width: 100px;
    white-space: nowrap;
    text-align: center;
    max-width: 300px;
}
table th:first-child,
table td:first-child {
    padding-left: 10px;
    border-radius: 8px 0px 0px 8px;
}
@media screen and (max-width: 576px) {
    table th:first-child,
    table td:first-child {
        padding-left: 7px;
    }
}
table th:last-child,
table td:last-child {
    padding-right: 10px;
    border-radius: 0px 8px 8px 0px;
}
@media screen and (max-width: 576px) {
    table th:last-child,
    table td:last-child {
        padding-right: 5px;
    }
}
table th:not(:first-child),
table td:not(:first-child) {
    padding-left: 7px;
}
table th:not(:last-child),
table td:not(:last-child) {
    padding-right: 7px;
}
table th:only-child,
table td:only-child {
    border-radius: 8px;
}
table thead tr th {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    font-weight: bold !important;
    font-size: 14px;
    color: #152567 !important;
}
@media screen and (max-width: 576px) {
    table thead tr th {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 12px;
    }
}
table tbody tr {
    cursor: pointer;
}
table tbody tr:nth-child(2n) {
    background-color: #f7f7f7;
}

table tbody tr td {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
table tbody tr td.no-data-found-cell {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
@media screen and (max-width: 768px) {
    table tbody tr td {
        padding: 8px 10px !important;
        font-size: 12px;
    }
}
table tbody tr:hover td {
    background-color: #007cbd 21;
}

.table-btn-action-cover {
    display: flex;
    align-items: center;
    justify-content: center;
}
.table-btn-action-cover .btn:not(:first-child) {
    margin-left: 10px;
}

table tbody tr.disabled td {
    border-bottom: 1px solid #eaeaea;
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
    background-color: white;
    user-select: none;
    background-color: #eccbd3;
}
table tbody tr.disabled td .checkbox label {
    cursor: not-allowed !important;
    opacity: .65;
}
