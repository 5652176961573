.page-login {
    background-image: linear-gradient(to right, #152567, #53c392);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.page-login .login-content {
    display: inline;
    border-radius: 8px;
    width: 900px;
    max-height: calc(100vh - 92px);
    min-height: 450px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #ebeef0;
    overflow: hidden;
}

.page-login .login-content .left-portion {
    width: 47%;
    position: relative;
    height: 100%;
}
.logo-image {
    position: absolute;
    width: 80px;
    top: 30px;
    left: 40px;
}
.page-login .login-content .left-portion .banner-img-cover {
    width: 100%;
}
.page-login .login-content .left-portion .banner-img-cover img {
    width: auto;
    height: auto;
    display: table-cell;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.page-login .login-content .right-portion .logo-image.for-mobile{
    display: none;
    position: inherit;
    margin: 20px auto;
}
.page-login .login-content .right-portion {
    width: 53%;
    padding: 40px 55px;
    overflow-y: auto;
    max-height: 532px;
}
.page-login .login-content .right-portion .forgot-pass-text-cover {
    text-align: right;
}
.page-login .login-content .right-portion .forgot-pass-text-cover a {
    color: #333;
    font-size: 14px;
    transition: 0.1s;
}

.page-login .login-content .right-portion .forgot-pass-text-cover a:hover {
    color: #152567;
}
.page-login .login-content .right-portion .btn-cover {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}
.page-login form{
    margin-top: 40px;
}

/* For responsive */

@media screen and (max-width: 991px) {
    .page-login{
        overflow: auto;
    }
    .page-login .login-content {
        overflow: inherit;
        max-height: initial;
        min-height: initial;
    }
    .page-login .login-content .left-portion {
        display: none;
    }
    .page-login .login-content .right-portion {
        width: 500px;
        max-width: 100%;
        margin: auto;
        max-height: inherit;
    }
    .page-login .login-content .right-portion .logo-image.for-mobile{
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .page-login {
        padding: 30px 10px;
        align-items: flex-start;
    }
    .page-login .login-content .right-portion {
        padding: 30px;
    }
}

@media screen and (max-width: 576px) {
    .page-login .login-content .right-portion{
        padding: 15px;
    }
    .page-login .login-content .right-portion h2.text-blue{
        font-size: 18px;
    }
    .page-login .login-content .right-portion .logo-image.for-mobile{
        margin-top: 0px auto 20px;
    }
}


.page-login{
    background-color: #ebeef0;
}
.page-login .login-content .right-portion .ui.segment.tab{
    background-color: #ebeef0;
}