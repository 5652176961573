header{
    height: 64px;
}

.logo {
    width: 72px;
}
.MuiAppBar-root {
    padding: 10px 24px 0 24px;
}
.breadcrumb-cover {
    background-color: white;
    padding: 12px 24px;
    font-size: 17px;
    font-weight: 800;
    border-bottom: 1px solid #edf0f2;
    height: 47px;
}
.menu-options-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -9px;
}
.menu-options-list .single-menu {
    position: relative;
}
.menu-options-list .single-menu:not(:last-child)::after {
    content: "";
    position: absolute;
    height: 20px;
    transform: translateY(-50%);
    right: 0px;
    width: 1px;
    top: 50%;
    background-color: #d9d9d9;
}
.menu-options-list .single-menu.active::after {
    display: none;
}
.menu-options-list .single-menu a {
    color: white;
    padding: 13px 20px;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    letter-spacing: 0.5px;
    min-width: 120px;
    white-space: nowrap;
    text-align: center;
    display: block;
    transition: 0.2s;
}
.menu-options-list .single-menu.active a {
    color: #53c392;
    background: white;
    border-radius: 8px 8px 0px 0px;
}

.menu-options-list .single-menu.active a::before {
    content: "";
    background-image: url("../../assets/images/header-curve-left.svg");
    position: absolute;
    bottom: 0px;
    left: -7px;
    height: 9px;
    background-repeat: no-repeat;
    width: 8px;
}
.menu-options-list .single-menu.active a::after {
    content: "";
    background-image: url("../../assets/images/header-curve-right.svg");
    position: absolute;
    bottom: 0px;
    right: -5px;
    height: 9px;
    background-repeat: no-repeat;
    width: 8px;
}

.menu-options-list .single-menu.active {
    margin-left: -1px;
}

.header-cover {
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 125px;
    z-index: 99;
}
.menu-cover {
    display: none;
}
.username {
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-right: 10px;
    width: 180px;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
}
.logo-cover {
    width: 210px;
}

@media screen and (max-width: 1023px) {
    .menu-options-list .single-menu a {
        color: white;
        padding: 13px 15px 13px;
        font-size: 13px;
        min-width: 90px;
    }
}
@media screen and (max-width: 767px) {
    header{
        height: 55px;
    }
    .logo-cover {
        width: auto;
    }
    .username {
        width: 100px;
        min-width: 100px;
        font-size: 14px;
    }
    .header-cover .right-portion {
        padding-top: 0px;
    }
    .menu-cover {
        display: block;
    }
    .menu-options-list {
        display: none;
    }
    .logo {
        width: 52px;
    }
    .MuiAppBar-root {
        padding: 10px 10px 5px 0px;
    }
    .breadcrumb-cover {
        padding: 12px 20px;
        font-size: 15px;
        height: 42px;
    }
}

@media screen and (max-width: 600px) {
    .mainWrapper {
        padding: 15px 20px !important;
    }
}
@media screen and (max-width: 576px) {
    .header-cover {
        height: 105px;
    }
    .mainWrapper {
        height: calc(100vh - 105px) !important;
    }
}
